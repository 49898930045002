// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// Angular material
@import "@angular/material/prebuilt-themes/indigo-pink.css";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1500px,
);

// Bootstrap
@import 'vendor/bootstrap-variables';
@import 'node_modules/bootstrap/scss/bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-free/css/all.min.css';

// Vendor overrides
@import 'vendor/material-styles';

body {
  //-webkit-text-size-adjust: 100%;
  /* Font variant */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
  //line-height: 1.25;
}

html, body {
  height: 100%;
}

.loading {
  position: relative;
  min-height: 100px;
  opacity: 0.5;

  &:after {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    background: url('~assets/images/loading-dots.svg') no-repeat center center;
    background-size: auto 15px;
  }
}

.document-bsn {
  background: url(~assets/images/no-bns-icon-4.svg);
}


.error-message {
  @include font-size($small-font-size);
  font-weight: $font-weight-normal;
  color: red !important;
}

.error-input {
  border-color: red !important;
}

.btn.btn-info[disabled] {
  background-color: darkgrey;
  border-color: darkgrey;
}

.button-lightblue {
  background-color: #81c8e8;
  border-color: #81c8e8;
  color: white;
}

.button-lightblue:hover {
  color: white;
  background-color: #5ABDEA;
  border-color: #5ABDEA;
}

.button-lightgrey {
  background-color: #ebebeb;
  border-color: #ebebeb;
  color: black;
}

.button-lightgrey:hover {
  background-color: #B9B9B9;
  border-color: #B9B9B9;
}

.mobile-workflow {
  @media only screen and (min-width: 600px) {
    display: none;
  }
}

.desktop-workflow {
  @media only screen and (max-width: 599.99px) {
    display: none;
  }
}

.text-pre-wrap {
  white-space: pre-wrap;
}


.ngx-mat-tel-input-container {

  .country-selector {
    opacity: 1 !important;
  }

  .mat-input-element {
    padding: 0 6px 0 95px;
  }
}

.btn-info {
  &:not([disabled]) {
    background-color: #17a2b8 !important;
    color: white;
  }
}

.text-info {
  color: #17a2b8 !important;
}

a, .btn-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
