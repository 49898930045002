/**
 CUSTOM STYLES FOR ANGULAR MATERIAL
 Here we add Angular Material Styles Overwrites
*/
// GENERIC STYLES
// --------------------
.mat-form-field,
.mat-radio-button,
.mat-expansion-panel {
  font-family: "Open Sans", sans-serif;
}

// Mat Form Field
// --------------------

// Mat Tab
// --------------------

// Mat Radio Button
// --------------------
.mat-radio-button {
  font-family: inherit;

  .mat-radio-outer-circle,
  .mat-radio-inner-circle {
    border-color: #81C8E8;
  }

  &.mat-accent {
    &.mat-radio-checked {
      .mat-radio-outer-circle,
      .mat-radio-persistent-ripple {
        border-color: #81C8E8;
      }
    }

    .mat-radio-inner-circle {
      background-color: #73C0E7;
    }

    .mat-radio-ripple {
      .mat-ripple-element {
        &:not(.mat-radio-persistent-ripple) {
          background-color: #73C0E7;
        }
      }
    }

    &:active {
      .mat-radio-persistent-ripple {
        background-color: #73C0E7;
      }
    }
  }
}

// Mat Expansion Panel
// --------------------
.mat-expansion-panel {
  &:not(.mat-elevation-z) {
    box-shadow: none;
  }

  .mat-expansion-indicator {
    padding-left: 5px;
    padding-right: 5px
  }

  .mat-expansion-panel-content,
  .mat-expansion-panel-header,
  .mat-expansion-panel-body {
    font: inherit;
  }

  .mat-expansion-panel-header,
  .mat-expansion-panel-body {
    padding: 0;
  }
}


