// Your variable overrides
// -----------------------
// Fonts
$font-family-base: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-weight-bold: 600 !default;

// Buttons
//$primary: #81C8E8;

//$enable-responsive-font-sizes: true;
